.gpt3__cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  padding: 2rem;
  margin: 4rem;
  border-radius: 1rem;
  background: var(--gradient-bar);
}

.gpt3__cta-content {
  display: flex;
  flex-direction: column;

  text-align: left;
  color: #fff;
}

.gpt3__cta-content p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
}

.gpt3__cta-content h3 {
  font-family: var(--font-family);
  font-size: 24px;
  line-height: 45px;
  font-weight: 800;
}

.gpt3__cta-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.gpt3__cta-btn button {
  background: #000;
  color: #fff;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-radius: 2rem;

  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 650px) {
  .gpt3__cta {
    flex-direction: column;
  }

  .gpt3__cta-btn {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__cta {
    flex-direction: column;
    margin: 4rem 2rem;
  }

  .gpt3__cta-content h3 {
    font-size: 18px;
    line-height: 32px;
  }

  .gpt3__cta-btn button {
    font-size: 14px;
    line-height: 28px;
  }
}
















.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}
.contact .info-box i {
  font-size: 30px;
  color: #ff8303;
  border-radius: 50%;
  padding: 10px;
}
.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}
.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #ff8303;
}
.contact .php-email-form input {
  padding: 10px 15px;
}
.contact .php-email-form textarea {
  padding: 12px 15px;
}
.contact .php-email-form button[type="submit"] {
  background: #ff8303;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}
.contact .php-email-form button[type="submit"]:hover {
  background: #ff8303;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h1 {
  font-size: 45px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;

  margin: 15px 0 0 0;
  color: #ff8303;
  display: inline-block;
}

.section-title h3 span {
  color: #ff8303;
}
.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}
@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}
