@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap");
.navbar {
  border-bottom: 1px solid rgb(236, 236, 236);
  background-color: white;
}
.navbar-brand {
  font-size: 30px;
  font-family: "Roboto Slab", serif;
  color: black;
}
.navbar-brand:hover {
  color: rgba(0, 40, 83, 1);
}

.nav-link {
  color: black !important;
  position: relative;
}
.nav-link:hover {
  color: rgba(0, 40, 83, 1) !important;
}
.nav-link::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 40, 83, 1);
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::before {
  transform: translate(-50%, 0) scaleX(1);
}
